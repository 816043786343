import * as R from 'ramda'
import Zousan from 'zousan'

import { getVenueDataFromUrls, buildStructures, createFetchJson, createFetchText, normalizeCoords } from './venueLoadingUtils.js'

const USE_AUTH_WHEN_NOT_PROD_STAGE = false // turning this off for now (per Jessica request)

async function create (app, config) {
  const log = app.log.sublog('venueDataLoader')
  let venueDataLoaded = new Zousan()
  let mapDataLoaded = new Zousan()

  const getDefaultStructureId = venueData =>
    venueData.defaultStructureId ||
    R.path(['structureOrder', 0], venueData) ||
    R.path(['selectorOrder', 0], venueData) ||
    R.pipe(R.prop('structures'), Object.values, R.path([0, 'id']))(venueData)

  const mergeWithProp = (prop, toAdd, o) => R.over(R.lensProp(prop), old => R.mergeRight(old || {}, toAdd), o)

  const getLanguageObject = (lang) => {
    const result = config.availableLanguages.find(el => el.langCode === lang)
    if (!result) {
      return { langCode: lang, assetSuffix: '' }
    }
    return result
  }

  async function loadVenueData (vConfig, languagesToTry) {
    // For all non-production stages, require SSO (if no assetStage defined we default to 'prod')
    if (vConfig.assetStage && vConfig.assetStage !== 'prod' && location.hostname !== 'localhost' && USE_AUTH_WHEN_NOT_PROD_STAGE)
      vConfig.auth = {
        type: 'cognito',
        config: {
          userPoolWebClientId: '484dghfpojlldauo956d84jtrj'
        }
      }

    const fetchJson = createFetchJson()
    const fetchText = createFetchText()

    const venueData = await getVenueDataFromUrls(vConfig, fetchJson, languagesToTry)

    const { accountId, venueId } = vConfig

    venueData.assetStage = vConfig.assetStage

    venueData.defaultOrdinal = venueData.defaultOrdinal || getDefaultOrdinal(venueData)
    venueData.structures = buildStructures(venueData)
    const currentLang = app.i18n().language
    const langObj = getLanguageObject(currentLang)
    venueData.bareVenueId = venueId.slice(0, venueId.length - langObj.assetSuffix.length)
    venueData.getTranslatedContentPath = contentType => `https://content.locuslabs.com/${venueData.category}/${contentType}/${venueId}/${accountId}`
    venueData.fetchJson = fetchJson
    venueData.fetchText = fetchText
    log.info('venueData ', venueData, langObj, currentLang)
    if (app.config.debug && app.env.isBrowser)
      window._venueData = venueData

    if (venueData.queueTypes)
      venueData.securityQueueTypes = (() => {
        const secLane = venueData.queueTypes.find(qt => qt.id === 'SecurityLane')
        if (secLane)
          return secLane.subtypes.map(t => t.id)
        return []
      })()

    app.bus.send('venueData/venueDataLoaded', { venueData })

    venueDataLoaded.resolve(venueData)
    return venueDataLoaded
  }

  function getDefaultOrdinal (venueData) {
    const defaultStructureId = getDefaultStructureId(venueData)
    const defaultStructure = Object.values(venueData.structures).find(R.propEq(defaultStructureId, 'id'))
    return defaultStructure.levels[defaultStructure.defaultLevelId].ordinal
  }

  function notifyState (venueData) {
    const state = { id: 'venueDataLoader' }
    if (venueData.id !== config.venueId)
      state.vid = venueData.id

    state.lang = app.i18n().language

    if (venueData.assetStage !== 'prod')
      state.stage = venueData.assetStage
    app.bus.send('deepLinking/notifyState', state)
    return venueData
  }

  app.bus.on('debugTools/fileDrop', async ({ file, content }) => {
    if (file.type === 'application/json') {
      const jsonOb = JSON.parse(content)
      if (jsonOb.basemap && jsonOb['basemap.venue'])
        return replaceTheme(JSON.parse(content)) // looks like a theme!
      if (jsonOb.metadata && jsonOb.metadata['mapbox:type'])
        return replaceStyle(content) // looks like a style!
    }
  })

  // Returns true if category c1 is within category c2.
  // "within" here means they either are the same category
  // or c2 is a subcategory of c1
  // i.e. withinCategory("eat", "eat") = true
  // withinCategory("eat.coffee", "eat") = true
  // withinCategory("eat", "eat.coffee") = false
  const withinCategory = (c1, c2) => c1 === c2 || c1.indexOf(c2 + '.') === 0

  function poiMapNameXForm (poi) {
    let name = poi.name
    if (!config.poiMapNameXForm)
      return name // no transforms for me today, thanks
    Object.keys(config.poiMapNameXForm)
      .filter(c2 => withinCategory(poi.category, c2))
      .forEach(c2 => {
        const xforms = config.poiMapNameXForm[c2] // an array of xforms
        xforms.forEach(xform => (name = name.replace(new RegExp(xform.replace), xform.with)))
      })
    return name
  }

  /*
    This function replaces POI labels with the poi.mapLabel (if it exists).
    If it does not exist and the config.copyPOINamesToMap is not explicitly set
    to `false` - then we copy the name from poi.name (and then put it through a
      set of transformations)
  */
  async function copyPOINames (mapFeatures) {
    const newMapFeatures = { ...mapFeatures }
    const pois = await app.bus.get('poi/getAll')

    Object.values(newMapFeatures)
      .forEach(layerArray =>
        layerArray
          .filter(f => f.properties.aiLayer === 'poi' && f.geometry.type === 'Point')
          .forEach(f => {
            const poi = pois[f.properties.id]
            if (!poi)
              log.warn(`Unknown poi in style: ${f.properties.id}`)
            else {
              if (poi.mapLabel)
                f.properties.text = poi.mapLabel
              else
                if (config.copyPOINamesToMap !== false)
                  f.properties.text = poiMapNameXForm(poi)
            }
          }))

    return newMapFeatures
  }

  // pass in theme object (parsed from theme file)
  function replaceTheme (mapThemeSource) {
    app.bus.send('map/replaceTheme', { theme: mapThemeSource })
  }

  function replaceStyle (mapStyleSource) {
    app.bus.send('map/replaceStyle', { styleSrc: mapStyleSource })
  }

  /**
   * Creates list of source ids from all floor ids and venue id and fetches GeoJSON features for each id.
   * Returns dictionary of source id to list of GeoJson features
   * @returns {Promise<Object<string, Array.<GeoJson>>>}
   */
  async function getMapFeatures (venueData) {
    return R.pipe(
      R.prop('structures'),
      R.map(R.prop('levels')),
      R.chain(R.keys),
      // Generate list of level IDs plus the venue ID to fetch feature JSON for each
      R.prepend(venueData.id),
      // eslint-disable-next-line no-template-curly-in-string
      R.map(geoJsonId => venueData.files.geoJson.replace('${geoJsonId}', geoJsonId)),
      R.map(venueData.fetchJson),
      R.map(R.andThen(featureJSON =>
        [featureJSON.id, enrichFeaturesForLevel(featureJSON.id, featureJSON.features, venueData.id, venueData.structures)])),
      promises => Zousan.all(promises),
      R.andThen(R.fromPairs)
    )(venueData)
  }

  const enrichFeaturesForLevel = (levelId, features, venueId, structures) => {
    const structureId = levelId.replace(/-[^-]*$/, '')
    const ordinalId = structureId === venueId
      ? 'landscape-background'
      : `ordinal: ${structures.find(R.hasPath(['levels', levelId])).levels[levelId].ordinal}`
    const enrichFeature = (feature) => {
      feature = mergeWithProp('properties', ({ venueId, structureId, ordinalId, levelId }), feature)
      feature = R.assoc('id', feature.properties.subid, feature)
      return feature
    }
    return features.map(enrichFeature)
  }

  /**
   * Fetches map style, map theme, map GeoJson feature sources.
   * Transforms data to convenient format and sends an event venueData/mapDataLoaded with map data.
   */
  app.bus.on('venueData/loadMap', async () => {
    venueDataLoaded.then(async venueData => {
      const mapStyleSource = await venueData.fetchText(venueData.files.style)
      const mapTheme = await venueData.fetchJson(venueData.files.theme)
      const badgesSpriteUrl = venueData.files.spritesheet
      const mapGlyphsUrl = venueData.files.glyphs
      const { id, bounds, structures, venueCenter, venueRadius, defaultOrdinal } = venueData
      const mapFeatures = venueData.venueList[id].mapTokens ? { [id]: [] } : await getMapFeatures(venueData).then(copyPOINames)
      const venueBounds = {
        n: bounds.ne.lat,
        s: bounds.sw.lat,
        e: bounds.ne.lng,
        w: bounds.sw.lng
      }
      const mapData = {
        mapFeatures,
        mapStyleSource,
        mapTheme,
        badgesSpriteUrl,
        mapGlyphsUrl,
        structures,
        defaultOrdinal,
        venueBounds,
        venueId: id,
        venueCenter,
        venueRadius,
        accountId: config.accountId,
        secure: config.auth !== undefined,
        tileServerAuthInfo: venueData.tileServerAuthInfo // only defined for tile maps
      }
      mapDataLoaded.resolve(mapData)
      app.bus.send('venueData/mapDataLoaded', mapData)
    })
  })

  // accept when shouldDisplay is null or undefined or true
  const shouldDisplayPredicate = building => building.shouldDisplay == null || building.shouldDisplay

  // todo check if all async events are still needed (events that sends a new event as result, like 'venueData/buildingSelectorDataLoaded')
  app.bus.on('venueData/loadBuildingSelectorData', () => {
    return venueDataLoaded.then(async venueData => {
      // displayable buildings with levels list ordered by ordinal desc.
      const buildings = venueData.structures
        .filter(shouldDisplayPredicate)
        .map(R.evolve({ // creates copy of structure with modified levels
          levels: R.pipe(R.values, R.sortWith([R.descend(R.prop('ordinal'))]))
        }))

      // todo order buildings using structureOrder and selectorOrder
      // currently structures ordering is duplicated in level selectors handlers
      // then we can remove structureOrder and selectorOrder from result
      const result = {
        buildings,
        structureOrder: venueData.structureOrder,
        selectorOrder: venueData.selectorOrder
      }
      app.bus.send('venueData/buildingSelectorDataLoaded', result)
      return result
    })
  })

  app.bus.on('venueData/normalizeCoords', ({ coords }) => venueDataLoaded.then(venueData => normalizeCoords(coords, venueData.bounds)))

  const noNavInfo = { edges: [], nodes: [] }
  app.bus.on('venueData/loadNavGraph', async () => {
    return venueDataLoaded.then(async venueData => {
      const navGraphData = venueData.files.nav ? (await venueData.fetchJson(venueData.files.nav)) : noNavInfo
      const navGraphOb = { navGraphData, structures: venueData.structures }
      app.bus.send('venueData/navGraphLoaded', navGraphOb)
      return navGraphOb
    })
  })

  app.bus.on('venueData/loadPoiData', async () => {
    return venueDataLoaded.then(async venueData => {
      const poisUrl = config.useOldDataModel
        ? venueData.files.poisOld || venueData.files.pois
        : venueData.files.pois || venueData.files.poisOld
      if (poisUrl) {
        const pois = await venueData.fetchJson(poisUrl)
        app.bus.send('venueData/poiDataLoaded', { pois, structures: venueData.structures })
      }
    })
  })

  app.bus.on('venueData/getVenueCenter', async () => venueDataLoaded.then(async venueData => (
    { lat: venueData.venueCenter[0], lng: venueData.venueCenter[1], ordinal: 0 })))

  app.bus.on('venueData/getContentUrl', ({ type, name = '' }) =>
    venueDataLoaded.then(venueData => venueData.files[type] + name))

  app.bus.on('venueData/getFloorIdToNameMap', () => venueDataLoaded.then(R.pipe(
    R.prop('structures'),
    R.map(R.prop('levels')), // get levels for each structure
    R.chain(R.values), // flatten structures levels into single array
    R.map(R.props(['id', 'name'])), // create pairs [id, name]
    R.fromPairs // create map of 'id' to 'name'
  )))

  app.bus.on('venueData/getFloorIdName', ({ floorId }) => {
    return venueDataLoaded.then(async venueData => {
      const structure = R.pipe(R.values, R.find(R.hasPath(['levels', floorId])))(venueData.structures)
      if (!structure) return null
      return {
        structureId: structure.id,
        structureName: structure.name,
        floorName: structure.levels[floorId].name
      }
    })
  })

  const getVenueDataProp = (propName, defaultValue) => () => venueDataLoaded.then(R.pipe(R.prop(propName), R.defaultTo(defaultValue)))

  app.bus.on('venueData/getVenueData', () => venueDataLoaded)

  app.bus.on('venueData/getVenueName', getVenueDataProp('name'))

  app.bus.on('venueData/getVenueCategory', getVenueDataProp('category'))

  app.bus.on('venueData/getVenueTimezone', getVenueDataProp('tz'))
  app.bus.on('venueData/getAccountId', () => config.accountId)
  app.bus.on('venueData/getVenueId', getVenueDataProp('id'))

  app.bus.on('venueData/getPositioningSupported', getVenueDataProp('positioningSupported'))
  app.bus.on('venueData/getStructures', getVenueDataProp('structures'))

  app.bus.on('venueData/loadNewVenue', async ({ venueId, accountId, assetStage = config.assetStage }) => {
    venueDataLoaded.reject(new Error('loadNewVenue called - previous loading ignored'))
    mapDataLoaded.reject(new Error('loadNewVenue called - previous loading ignored'))
    venueDataLoaded = new Zousan()
    mapDataLoaded = new Zousan()
    loadVenueData({ ...config, venueId, accountId, assetStage }, [])
      .then(notifyState)
  })

  app.bus.on('venueData/changeVenueLanguage', async ({ lang }) => {
    return venueDataLoaded.then(async venueData => {
      const langObj = getLanguageObject(lang)
      const newVenueId = `${venueData.bareVenueId}${langObj.assetSuffix}`
      app.bus.send('venueData/loadNewVenue', { accountId: config.accountId, venueId: newVenueId })
    })
  })

  // returns a full URL to an image hosted on img.locuslabs.com, size has to be a string of format ${width}x${height}
  app.bus.on('venueData/getPoiImageUrl', ({ imageName, size }) => {
    return `https://img.locuslabs.com/resize/${config.accountId}/png/transparent/${size}contain/poi/${imageName}`
  })

  // This is an utility function that returns a unique ID used to distinguish certain, installation/deployment specific parts
  // for now it uses venueId and accountId and is used to fix collision when storing data in localStorage
  app.bus.on('venueData/getDistributionId', () => {
    return venueDataLoaded.then(venueData => {
      return `${venueData.bareVenueId}-${config.accountId}`
    })
  })

  app.bus.on('venueData/getCustomKeywords', () =>
    venueDataLoaded.then(venueData => {
      const searchUrl = config.useOldDataModel
        ? venueData.files.searchOld || venueData.files.search
        : venueData.files.search
      return venueData.fetchJson(searchUrl)
    }))

  app.bus.on('venueData/isGrabEnabled', getVenueDataProp('enableGrab'))

  app.bus.on('venueData/getGrabPoiIds', getVenueDataProp('grabPoiIds', []))

  app.bus.on('venueData/getAssetsTimestamp', getVenueDataProp('version'))

  app.bus.on('venueData/getTranslatedFloorId', async ({ floorId }) => {
    return venueDataLoaded.then(venueData => {
      const currentLang = app.i18n().language
      const langObj = getLanguageObject(currentLang)
      return `${venueData.bareVenueId}${langObj.assetSuffix}-${floorId.split('-').slice(1).join('-')}`
    })
  })

  /**
   * Returns object with queue types (security and immigration lanes) that are present in the venue defined in venue data.
   * Also adds image id for lanes with images.
   *
   * @typedef QueueType
   * @property {boolean} default
   * @property {string} defaultText
   * @property {string} id
   * @property {string} imageId
   *
   * @typedef QueueTypes
   * @property {QueueType[]} SecurityLane - list of security categories
   * @property {QueueType[]} ImmigrationLane - list of immigration categories
   *
   * @return {QueueTypes} queueTypes
   */
  app.bus.on('venueData/getQueueTypes', () => {
    return venueDataLoaded.then(venueData => {
      const lanesWithImages = ['tsapre', 'clear', 'globalEntry']
      if (venueData.queueTypes) {
        return venueData.queueTypes.reduce((obj, category) => {
          const { id, subtypes } = category
          const typesWithImages = subtypes.map(type => {
            const imageId = lanesWithImages.includes(type.id) && `security-logo-${type.id.toLowerCase()}`
            return { ...type, imageId }
          })
          obj[id] = typesWithImages
          return obj
        }, {})
      } else return {}
    })
  })

  const runTest = async ({ testRoutine, reset = false, venueData = null }) => {
    if (reset || venueData) {
      venueDataLoaded = new Zousan()
      mapDataLoaded = new Zousan()
    }
    if (venueData)
      venueDataLoaded = Zousan.resolve(venueData)

    await testRoutine()

    let venueDataObj, mapDataObj
    if (venueDataLoaded.v)
      venueDataObj = await venueDataLoaded
    if (mapDataLoaded.v)
      mapDataObj = await mapDataLoaded
    return { venueDataObj, mapDataObj }
  }

  const init = async () => {
    const params = new URLSearchParams(typeof window === 'undefined' ? '' : window.location.search)
    const lang = params.get('lang')
    // On first load there is no URL param for lang unless a customer has created one on purposed, in which case that languauge will be loaded in the UI and the map. If it is first load and no language is specified by the param, grab the browser's list of preferred languages. We will then try to display a map venue for their preferred languages in order if we have it.
    let languagesToTry = []
    if (lang === null) { // So if it is first load
      languagesToTry = typeof window === 'undefined' ? [] : navigator.languages // grab languages to use for alternate maps from the browser's list of preffered languages
    }
    const deepLinkProps = config.deepLinkProps || {}
    if (deepLinkProps.lang) languagesToTry.unshift(deepLinkProps.lang)
    const venueId = deepLinkProps.vid || config.venueId
    const assetStage = config.useDynamicUrlParams && deepLinkProps.stage
      ? deepLinkProps.stage
      : config.assetStage
    const accountId = deepLinkProps.accountId || (assetStage === 'alpha' ? 'A1VPTJKREFJWX5' : config.accountId)
    loadVenueData({ ...config, venueId, accountId, assetStage }, languagesToTry)
      .then(notifyState)
  }

  return {
    init,
    runTest,
    internal: {
      getDefaultStructureId,
      setConfigProperty: (key, value) => { config[key] = value }
    }
  }
}

export {
  create
}
